import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutPaymentInfo from "./CheckoutPaymentInfo";

import "./CheckoutForm.css";
import WebPaymentForm from "./WebPaymentForm";
import SamaMoneyForm from "./SamaMoneyForm";
import {sendAlert} from "./Alert";
import {Grid} from "@material-ui/core";

const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const promise = stripeKey ? loadStripe(stripeKey) : null;

const ELEMENTS_OPTIONS = {
    fonts: [
        {
            cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
        },
    ],
};

const TOKEN = process.env.REACT_APP_AUTHORIZATION; // Test
console.log("stripe token", TOKEN);

export default function CheckoutForm(props) {
    const [value, setValue] = React.useState(0);
    const [type, setType] = React.useState('card');

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setType(newValue === 0 ? 'card' : 'iban');
    };

    const handleSuccess = (event) => {
        sendAlert({
            type: 'alert-event',
            alert: 'success',
            title: 'Payment Form',
            message: 'Merci pour votre contribution'
        });
        if (typeof props.onSuccess === 'function') {
            props.onSuccess(event);
        }
    }

    return (
        <Grid className="auto-container">
            <Tabs variant="scrollable"
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab value={0} label="Carte Bancaire"/>
                {/*<Tab value={1} label="Virement SEPA"/>*/}
                <Tab value={2} label="Sama Money"/>
                <Tab value={3} label="Orange Money"/>
            </Tabs>
            {value === 0  && <Elements stripe={promise} options={ELEMENTS_OPTIONS}>
                <CheckoutPaymentInfo type={type} token={TOKEN} onSuccess={handleSuccess}/>
            </Elements>}
            {value === 2 && <SamaMoneyForm onSuccess={handleSuccess}/>}
            {value === 3 && <WebPaymentForm onSuccess={handleSuccess}/>}
        </Grid>
    );
}